import React from 'react';
import './Homethird.css';
import GRAPHICS from '../Videos/GRAPHICS.m4v';
import { Link } from 'react-router-dom';


const Homethird = () => {

return (
    <section>
        <div className='h3-main'>
            <h1 className='h3-head' data-aos='zoom-in-up' 
            data-aos-easing="linear"
            data-aos-duration="1500">Why is livwell a fore-runner in agri & nutraceuticals -</h1>
            <div className='vedio-wrapper'>
            <div  className='h3-right'  data-aos='zoom-in' 
            data-aos-easing="linear"
            data-aos-duration="1500">
                <video src={GRAPHICS} 
                className='h3-vedio'  
                autoPlay
                controls
                loop
                muted
                 />
                </div>
                <ul className='h3-content' data-aos='zoom-in-up' 
            data-aos-easing="linear"
            data-aos-duration="1500">
                <li>Best Mode of Nutrition Delivery</li>
                <li>Better absorption on plants & animals </li>
                <li>All ingredients are taken from botanical extract (chemical free)</li>
                <li>Non-Hazardous</li>
                <li>Non-Toxic</li>

                <button className='certi-btn' data-aos='zoom-in-up' 
            data-aos-easing="linear"
            data-aos-duration="1500">
                    <Link to='/ProductBrand' 
                    className='certi-click'>
                    Know more
                </Link></button>
                </ul>
                
            </div>
            
        </div>
            
             
      </section>
    )
}

export default Homethird;
