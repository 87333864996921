import React from 'react'
import { Link } from 'react-router-dom';
function Error() {
    return (
        <div style={{textAlign:'center', margin:'200px'}}>
            <h1>404 | Error This page is doesn't exit.</h1>
            <Link to='/'>
            <span>Go Back Home page</span>
            </Link>
        </div>
    )
}

export default Error;
