import React from 'react';
import { Link } from 'react-router-dom';
import './HomeCard.css';

function CardBox(props) {
    return (
        <>
        <div className='a-box'>
            <div className='a-b-head'>
                <Link to='/Services' className='head-link'>
                <h2>{props.head}</h2>
                </Link>
                 
            </div>
            <div className='s-b-text'>
                <ul>
                <li>{props.disc1}</li>
                <li>{props.disc2}</li>
                <li>{props.disc3}</li>
                <li>{props.disc4}</li>
                <li>{props.disc5}</li>
                <li>{props.disc6}</li>
                </ul>
                <p className='card-word'>{props.word}</p>
                
                </div>
                <div className='agri-btn'>
            <button className='certi-btn'>
                <Link to="/ProductBrand" 
                className='certi-click'>
                Know more
            </Link></button>
            
            </div>
            </div>
            
        </>
    )
}

export default CardBox;