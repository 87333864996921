import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';
import { Helmet } from 'react-helmet';
import ReactWhatsapp from 'react-whatsapp';
import service from '../../Images/s-banner.jpg';
import icon4 from '../../Images/Agriculture.png';
import icon5 from '../../Images/animal.png';
import icon6 from '../../Images/nano.png';
import icon7 from '../../Images/soil.png';
import icon8 from '../../Images/crops.png';
import icon9 from '../../Images/drone.png';
import icon10 from '../../Images/dieses.png';
import icon11 from '../../Images/agri-AI.png';
 

function Services() {

    const TITLE = 'LivWell Services'
    return (

        <>

        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
            <div  className='s-bg' data-aos='zoom-in-up' 
            data-aos-easing="linear"
            data-aos-duration="1500">
               <img src={service}
                alt='service' />
            </div> 

            <div  className="s-wrapper">
         <div  className="s-box">
            <div  className="front-face">
               <div  className="s-icon" data-aos='flip-left' 
            data-aos-easing="linear"
            data-aos-duration="1500">
               <img src={icon4} className='small-icon' alt=''/>
               </div>
               <span className='icon-head1'>Agriculture</span>
            </div>
            <div  className="back-face">
               <span>Agriculture</span>
               <p>
              <li>  Soil Advisory</li>
              <li>  Crop Advisory </li>
              <li>  Agriculture AI </li>
              <li>  Diseases control </li>
              <li>  Chlorophyl profiling </li>
              <li>  Soil & Plant nutrient </li>
               </p>
            </div>
         </div>
         <div  className="s-box">
            <div  className="front-face">
               <div  className="s-icon" data-aos='flip-right' 
            data-aos-easing="linear"
            data-aos-duration="1500">
               <img src={icon5} className='small-icon' alt=''/>
               </div>
               
               <span  className='icon-head2'>Animal feed</span>
               
            </div>
            <div  className="back-face">
               <span><Link to='/AnimalDetails' className='back-link'>Animal feed</Link></span>
               <li>  Nutritional Feed </li> 
               <li>  Supplement for live stock</li>
              <li>  Milching animals </li>
              <li>  Poultry</li>
              <li>  Piggry </li>
            </div>
         </div>
         <div  className="s-box">
            <div  className="front-face">
               <div  className="s-icon" data-aos='flip-left' 
            data-aos-easing="linear"
            data-aos-duration="1500">
                  <img src={icon6} className='small-icon' alt=''/>
               </div>
               <span className='icon-head3'>Nano disinfekt</span>
            </div>
            <div  className="back-face">
               <span>Nano disinfekt</span>
               <li>  Hand cop</li>
               <li>  Home clean</li>
              <li>  Fogging spray </li>
              <li>  Fruggie clean</li>
              <li>  Laundry clean </li>
              <li>  Travel cop </li>
            </div>
         </div>
      </div>

      <div className='sr-main'>
          <h1 className='sr-head'>Full smart work on agriculture advisory in our services</h1>
      </div>
      <div className='sr-wrapper'>
      <div className='sr-flex'>
         <img src={icon7} className='sr-icon' alt='' />
         <h4 className='sr1-head'>Soil Advisory</h4>
         <span className='sr-desc'> We facilitate the services for onsite testing of soil for their micro & macro minerals, PH, electrical conductivity- the data will be available on the cloud and based the data we will customise our product to suit the soil and the crop.</span>
      </div>

      <div className='sr-flex'>
         <img src={icon8} className='sr-icon' alt='' />
         <h4 className='sr1-head'>Crop Advisory</h4>
         <span className='sr-desc'> We aim to enable the farmer to double its income by advising them on crop intensification both in terms of frequency & multi-layer farming. We also advise them to create a fixed deposit for long term and long-lasting gain by adopting to agro forestry.</span>
      </div>

      <div className='sr-flex'>
         <img src={icon9} className='sr-icon' alt='' />
         <h4 className='sr1-head'>Agriculture AI</h4>
         <span className='sr-desc'> Integration of all the data related to Soil and Plants will be done with Geo tagging of the Farm Land. We intend to maintain the Farmer’s Diary on cloud and enables them to limit the consumption of water, fertilizers, Fungicides and pesticides. WE intend to provide complete block chain of product to end consumer.</span>
      </div>

      <div className='sr-flex'>
         <img src={icon10} className='sr-icon' alt='' />
         <h4 className='sr1-head'>Diseases control</h4>
         <span className='sr-desc'>Just click photographs of Infected Leaves, Fruits and stems and upload them to portal, our experts will revert within 24 days with a recommended solution for the same.</span>
      </div>

      <div className='sr-flex'>
         <img src={icon11} className='sr-icon' alt='' />
         <h4 className='sr1-head'>Chlorophyl profiling</h4>
         <span className='sr-desc'>We intend to promote the farmers to go for this service so that we can understand what product we are using as inputs and what exactly we are consuming (analysis of harmful residue of chemicals present on the produce).</span>
      </div>
      </div>
      <div>
   <ReactWhatsapp
        number='+91 8368844238'
        className="whatsapp_float"
        message='Hello!'>
        <i className="fa fa-whatsapp whatsapp-icon"></i>
    </ReactWhatsapp>
     </div>
        </>
    );
}

export default Services;
