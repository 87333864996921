import React from 'react';
import {BrowserRouter as Router, Switch, Route  } from 'react-router-dom';
import Home from './Components/Home';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import About from './Components/Pages/About';
import Contact from './Components/Pages/Contact';
import Services from './Components/Pages/Services';
import ProductBrand from './Components/Pages/ProductBrand';
import Error from './Components/Pages/Error';
import AnimalDetails from './Components/Pages/AnimalDetails';
 
 
const App = () => {

    return (

    <div>
     <Router>
       <Navbar />
       <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/About" component={About} />
            <Route exact path="/Services" component={Services} />
            <Route exact path="/ProductBrand" component={ProductBrand} />
            <Route exact path="/Contact" component={Contact} />
            <Route exact path="/AnimalDetails" component={AnimalDetails} />
            <Route path='/' component={Error} />
       </Switch>
       <Footer/>
     </Router>
     </div>
  );
};

export default App;
