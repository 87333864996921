import React from 'react';
import './Hometesti.css';
import mypdf from '../Images/PagroCerti.pdf';
import mypdf1 from '../Images/Ranicherra-certi.jpeg'; 
import v3 from '../Videos/Karshaka-Mitra.mp4';
import v4 from '../Videos/shade-managment.mp4';


function Hometesti() {
 
   
    return (
        <section>
        <div className='h4-main'>
            <h1 className='h4-head' data-aos='zoom-in-up' 
            data-aos-easing="linear"
            data-aos-duration="1500">Our products uses and testimonials</h1>
            <div className='h4-certi'>
                 
                <div className='h4-btn'>
                <h4 className='certi-head' data-aos='fade-left' 
            data-aos-easing="linear"
            data-aos-duration="1500">Testimonial certificates</h4>
                <button className='certi-btn' data-aos='fade-right' 
            data-aos-easing="linear"
            data-aos-duration="1500">
                    <a href={mypdf} className='certi-click' target='_blank'  rel='noopener noreferrer'>Certificate 1</a> 
                </button>
                <button className='certi-btn' data-aos='fade-left' 
            data-aos-easing="linear"
            data-aos-duration="1500">
                    <a href={mypdf1} className='certi-click' target='_blank'  rel='noopener noreferrer'>Certificate 2</a> 
                </button>
                </div>
                </div>
                </div>
        <div className='h4-wrapper'>
        <div className='h4-center'>
        <video className='h4-video'
           src={v3}
           autoPlay
           muted
           loop
           controls />
           </div>
        <div className='h4-center'>   
        <video className='h4-video'
                src={v4}
                autoPlay
                loop
                controls
                muted />
            </div>
            </div>
        </section>
    )
}

export default Hometesti;
