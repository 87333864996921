import React from 'react';
import './About.css';
import { Helmet } from 'react-helmet';
import about from '../../Images/about-bg.jpg';
import icon from '../../Images/vision.png';
import icon1 from '../../Images/journey.png';
import ReactWhatsapp from 'react-whatsapp';


function About() {

    const TITLE = 'LivWell About' 
         
    return (
        <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
        <section className='about-main'>
        <div className='about--bg' data-aos='zoom-in-up' 
            data-aos-easing="linear"
            data-aos-duration="1500">
             <img src={about}
              alt='about'/>
        </div>
        
        <div className='about-wrapper'>
        <div className='vision-left'>
                <h1 className='about-head'>Our vision</h1>
                <span className='about-desc'>
                Environmental & Ecological - To naturalise the Human life by enhancing the nutritional profile of Livestock, soil, Plant & final produce, which has got effected due to heavy usage chemicals and pesticides in daily consumables. <br></br><br></br>
                Social Objective – Help framers to double their income by 2025 without using harsh chemicals as a result of which they can feed domestic as well as international markets. 
                </span>
                </div>
             <div className='vision-right' data-aos='flip-down' 
            data-aos-easing="linear"
            data-aos-duration="1500">
             <img src={icon} className='vision-icon' alt='vision'/>
             </div>
             </div>

            <div className='journey-wrapper'>
            <div className='journey-right'>
                <h1 className='about-head'>Our journey</h1>
                <span className='about-desc'>
                Our journey began with a vision to create simple, innovative range of Agriculture, Animal Husbandry, Nutraceutical and personal hygiene products emerging from a revolutionary Green-Nanotechnology that empower all beings to live a healthy lifestyle. <br></br><br></br>
                We are living in a world where every day we are consuming t chemically grown and highly intoxicated products and which are harmful for our health and for the ecosystem.<br></br><br></br>
                LivWell intends to develop unique brands in our pursuit of a healthier and happier world through nanotechnology driven Agri product, healthy & diseases free animals, nutraceutical with highest efficacies.<br></br><br></br>
                We are driven by our vision to simplify lives by developing 100% safe, non-toxic and environmental friendly products. Our endeavour is to inculcate a habit of leading chemical free & toxic free healthy life.
                </span>
                </div>
                <div className='journey-left' data-aos='flip-up' 
            data-aos-easing="linear"
            data-aos-duration="1500">
             <img src={icon1} className='journey-icon' alt='vision'/>
             </div>
             </div>
             </section>       
   <ReactWhatsapp
        number='+91 8368844238'
        className="whatsapp_float"
        message='Hello! '>
        <i className="fa fa-whatsapp whatsapp-icon"></i>
    </ReactWhatsapp>
     
        </>
    )
}

export default About;
