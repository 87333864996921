import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; 



function Footer() {
    return (
        <div>
            <footer>
    <div className="footer-content">
      <div className="top">
        <div className="logo-details">
           
        <span  className="logo_name">Liv<span className='color-black'>Well<span className='third-color'> products</span></span></span>
        </div>
        <div  className="media-icons" >
          <a href="https://www.facebook.com/livwellproducts"><i  className="fab fa-facebook-f"></i></a>
          <a href="https://www.instagram.com/livwellproducts"><i  className="fab fa-instagram"></i></a>
          <a href="https://www.linkedin.com/livwellproducts"><i  className="fab fa-linkedin-in"></i></a>
          <a href="https://www.youtube.com/channel/UC7Hi2h_1rXfA3U70RVXAr8A/featured"><i  className="fab fa-youtube"></i></a>
        </div>
      </div>
      <div  className="link-boxes">
        <ul  className="footer-box">
          <li  className="link_name">About Us</li>
          <p  className='footer-about'>LivWell intends to create simple, innovative products emerging from a unique Nanotechnology with
           the aim to revolutionise the way farming is happening in India. Our products are 100% natural and have been extracted 
           from botanical sources </p>
        </ul>
        <ul  className="footer-box">
          <li  className="link_name">Services</li>
          <li><Link to="/Services">Agriculture</Link></li>
          <li><Link to="/Services">Animal feed supplement</Link></li>
          <li><Link to="/Services">Nano disinfekt</Link></li> 
        </ul>
       
        <ul  className="footer-box">
          <li  className="link_name">Address</li>
          <li className='link-desc'><Link to="/"><i  className=" footer__fa fa fa-phone"></i><br></br> +91 8368844238</Link></li>
          <li className='link-desc'><Link to="/"><i  className=" footer__fa fa fa-envelope"></i>  info@livwellproducts.in</Link></li>
          <li className='link-desc'><Link to="/"><i  className=" footer__fa fa fa-map-marker"></i> Address </Link></li>
        </ul>
         
      </div>
    </div>
    <div  className="bottom-details">
      <div  className="bottom_text">
        <span  className="copyright_text">Copyright © 2021</span>
        <span> <Link to="/">LivWell</Link>All rights reserved</span>
        <span  className="policy_terms">
          <Link to="/">Terms & conditions</Link>
        </span>
        <span>Design by Moin</span>
      </div>
    </div>
  </footer>
        </div>
    )
}

export default Footer;
