import React from 'react';
import './Contact.css';
import { Helmet } from 'react-helmet'; 
import Phone from '../../Images/phone.png';
import email from '../../Images/email.png';
import location from '../../Images/location.png';
import ReactWhatsapp from 'react-whatsapp';



const Contact = () => {

    const TITLE = 'LivWell Contact Us'
     

    return (
         <>
         <Helmet>
          <title>{ TITLE }</title>
        </Helmet>

        <div className="c">
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Let's discuss your problems</h1>
          <div className="c-info">
            <div className="c-info-item">
              <img src={Phone} alt="" className="c-icon" />
              +91 8368844238
            </div>
            <div className="c-info-item">
              <img className="c-icon" src={email} alt="" />
              info@livwellproducts.in
            </div>
            <div className="c-info-item">
              <img className="c-icon" src={location} alt="" />
               Address
            </div>
          </div>
        </div>
        <div className="c-right">
          <p className="c-desc">
            <b>What’s your query?</b> Get in touch. Always available
          </p>  
          <form action="https://formsubmit.co/info@livwellproducts.in" method="POST">
            <input type="text" required placeholder="Full name" name="user_name" />
            <input type="number" required  placeholder="Phone" name="user_phone" />
            <input type="email" required  placeholder="Email id" name="user_email" />
            <textarea rows="5" required  placeholder="Type your message..." name="message" />
            <button>Submit</button>
          </form>
        </div>
      </div>
    </div>
    <div>
   <ReactWhatsapp
        number='+91 8368844238'
        className="whatsapp_float"
        message='Hello! '>
        <i className="fa fa-whatsapp whatsapp-icon"></i>
    </ReactWhatsapp>
     </div>
         </>
    );
};

export default Contact;
