import React, {useState} from 'react';
import { Link} from 'react-router-dom';
import './Navbar.css';
import logo from '../Images/livwell.png';



function Navbar() {
    const [click, setClick] = useState(false);
     

    const handleClick = () => setClick(!click);
     

        return(
         
           <nav className="Navbar" >
           <div className = "Navbar-container">
            <Link to='/'>  
            <img src={logo}
             className='nav-logo' 
             width='150px' 
             alt='logo' /> 
            </Link>
           
            <div className="menu_icon" onClick={handleClick}>
                <i className={click ? "fas fa-times" : "fas fa-align-right"} />
            </div>

            <ul className={click ? "Nav-menu active" : "Nav-menu"}> 
            <li className="Nav-item">
               <Link to='/' className='Nav-links' >
                Home
               </Link>
            </li>

            <li className="Nav-item">
               <Link to='/About' className='Nav-links' >
               About Us
               </Link>
            </li>

            <li className="Nav-item">
               <Link to='/Services' className='Nav-links' >
                  Services
               </Link>
            </li>

            <li className="Nav-item">
               <Link to='/ProductBrand' className='Nav-links' >
                  Products & Brands
               </Link>
            </li>
               
            <li className="Nav-item">
               <Link to='/Contact' className='Nav-links' >
                  Contact Us
               </Link>
            </li>             

            </ul>
           
        </div>
    </nav>
    );
}

export default Navbar;
