import React from 'react';
import './Home.css';
import Carousel from 'react-bootstrap/Carousel';
import slide1 from "../Images/slide1.jpg";
import slide2 from "../Images/slide2.jpg";
import slide3 from "../Images/slide3.jpg";
import slide4 from "../Images/slide4.jpg";
import slide5 from "../Images/slide5.jpg";
import slide6 from "../Images/slide6.jpg";
import 'bootstrap/dist/css/bootstrap.css';
import HomeCard from './HomeCard';
import ReactWhatsapp from 'react-whatsapp';
import Homethird from './Homethird';
import Hometesti from './Hometesti';


function Home() {



    return (
      <section>
        <div className='main-slide'>
            <Carousel interval={1500}>
        <Carousel.Item>
    <img
      className="mobile-v d-block"
      src= {slide1}
      
      alt="First slide"
    />
  </Carousel.Item>
 
  <Carousel.Item>
    <img
      className="mobile-v d-block" 
      src={slide2}
       
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="mobile-v d-block"  
      src={slide3}
       
      alt="Third slide"
    />
   
  </Carousel.Item>

  <Carousel.Item>
    <img
      className="mobile-v d-block"
      src={slide4}
       
      alt="Third slide"
    />
   
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="mobile-v d-block"
      src={slide5}
       
      alt="Third slide"
    />
   
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="mobile-v d-block"
      src={slide6}
       
      alt="Third slide"
    />
   
  </Carousel.Item>
   
</Carousel>

      <div>
    </div>
   </div>
   <div className='chat-animate'>
   <ReactWhatsapp
        number='+91 8368844238'
        className="whatsapp_float"
        message='Hello! '>
        <i className="fa fa-whatsapp whatsapp-icon"></i>
    </ReactWhatsapp>
     </div>
   <HomeCard/>
   <div>
     <Homethird/>
   </div>
   <div>
     <Hometesti/>
   </div>
   </section>
    );
}

export default Home;