import React from 'react'
import './HomeCard.css';
import HomeCardBox from './HomeCardBox'; 

function HomeCard() {

    return (
        <div>
        <div id='card'>
            <h1 className='top-head' data-aos='zoom-in'>Single platform for complete farm ecosystem</h1>
            <div className='a-container' 
            data-aos='zoom-in-up' 
            data-aos-easing="linear"
            data-aos-duration="1500">
                <HomeCardBox head='Agriculture' 
                disc1='1. Soil Advisory' 
                disc2='2. Crop Advisory' 
                disc3='3. Agri AI'
                disc4='4. Diseases control'
                disc5='5. Chlorophyl profiling'
                disc6='6. Soil & Plant nutrient'
                word =' '
                />
                

                <HomeCardBox head='Animal feed' 
                disc1='1. Nutritional Feed' 
                disc2='2. Supplement for live stock' 
                disc3='3. Milching animals' 
                disc4='4. Poultry'
                disc5='5. Piggry'
                disc6='6. Cattle feed'
                word ='To overcome feed cost we have launched Nano Nutrient feed supplement for animals & birds. These supplements helps to build up better health for animal to deliver higher milk/meat yield. Nano Nutrient feed supplement is designed to minimize cost while at the same time provide the required nutrition such as protein, minerals, vitamins, carbohydrates and fats to both mulching animals and gains weight in meat providing animals & birds . Our product aims to get animals to milk to their genetic potential and to gain weight to its right potential for meat providing animals/birds to maximize profit for their owners. At the same time our product optimizes the health and future reproductive performance of animals.'
                />

                <HomeCardBox head='Nano disinfekt' 
                disc1='1. Hand cop' 
                disc2='2. Fogging spray' 
                disc3='3. Fruggie clean'
                disc4='4. Home clean'
                disc5='5. Laundry clean'
                disc6='6. Travel cop'
                word =' '
                />
            </div> 
        </div>
    </div>
        
        
    )
}

export default HomeCard;