import React from 'react'
import './AnimalDetails.css';
import { Helmet } from 'react-helmet';

 function AnimalDetails() {

    const TITLE = 'LivWell Animal Details' 

    return (
        <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
        <section className='Ad--head'>
            <h1 className='Ad-heading'> Animal Feed</h1>
        <div className='pr--content'>
          <h3 className='pr-head'>COMMING SOON......</h3>
          
        </div>
        </section>
        </>
    )
}
export default AnimalDetails;