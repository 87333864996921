import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from "react";
import './ProductBrand.css';
import { Helmet } from 'react-helmet';
import ReactWhatsapp from 'react-whatsapp';
import product from '../../Images/product-bg.jpg';
import img from '../../Images/riddhi.png';
import img1 from '../../Images/siddhi.png';
import img2 from '../../Images/viriddhi.png';
import img3 from '../../Images/suraksha.png';
import img4 from '../Pages/Image/Hand-Cop.png';
import img5 from '../Pages/Image/Fogging-Spray.png';
import img6 from '../Pages/Image/Fruggie-Clean.png';
import img7 from '../Pages/Image/Home-Cleaner.png';
import img8 from '../Pages/Image/Travel-Cop.png';
import img9 from '../Pages/Image/Laundry-Clean.png';
import v5 from '../../Videos/Nino-Gold.m4v'
import v6 from '../../Videos/PortfolioFilm.mp4'

function ProductBrand() {

    const TITLE = 'LivWell Brand & Products' 

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
      setToggleState(index);
    };

    return (
        <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
        <section>
        <div className='pr-main'>
        <div className='pr-bg'>
             <img src={product}
              alt='product'/>
        </div>
        
        <h1 className='pr-head'>Livwell enhance bussiness and agriculture</h1>
        <div className='pr-wrapper'>
        <div className='pr-li'>
            <p className={toggleState === 1 ? "pr-nav active-pr-nav" : "pr-nav"} onClick={() => toggleTab(1)}><span>Agriculture</span></p>
            <p className={toggleState === 2 ? "pr-nav active-pr-nav" : "pr-nav"} onClick={() => toggleTab(2)}><span> Animal feed</span></p>
            <p className={toggleState === 3 ? "pr-nav active-pr-nav" : "pr-nav"} onClick={() => toggleTab(3)}><span>Nano disinfekt</span></p>
            </div> 
            <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}>
          <h4>Agriculture </h4>
          <hr />
          <div className='pr--container'>
          <div className='content-list'>
            <li className='tab-content'>Riddhi</li>
            <li className='tab-content'>Siddhi</li>
            <li className='tab-content'>Vriddhi</li>
            <li className='tab-content'>Suraksha</li>
            <li className='tab-content'>Suraksha anti-microbial</li>
            <li className='tab-content'>Suraksha anti-thrip</li>
            <li className='tab-content'>Cattle nutrient</li>
          </div>
          </div>
          <div className='pr-brand'>
            <div className='pr-list'>
            <img className='pr-riddhi'
                src={img}
                alt='riddhi' />
                <span className='pr-content-1'><span className='pr-color1'>Riddhi</span></span>
            </div>

            <div className='pr-list'>
            <img className='pr-riddhi'
                src={img1}
                alt='riddhi' />
                <span className='pr-content-1'><span className='pr-color2'>Siddhi</span></span>
            </div>

            <div className='pr-list'>
            <img className='pr-riddhi'
                src={img2}
                alt='riddhi' />
                <span className='pr-content-1'><span className='pr-color3'>Vriddhi</span></span>
            </div>

            <div className='pr-list'>
            <img className='pr-riddhi'
                src={img3}
                alt='riddhi' />
                <span className='pr-content-1'><span className='pr-color4'>Suraksha</span></span>
            </div>
            </div>
        </div>
        
        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          
          <div className='Animal-container'>
          <h4>Animal feed</h4>
          <hr />
          <div>
          <p className='content-p'>To overcome feed cost we have launched Nano Nutrient feed supplement for animals & birds. These supplements helps to build up better health for animal to deliver higher milk/meat yield. Nano Nutrient feed supplement is designed to minimize cost while at the same time provide the required nutrition such as protein, minerals, vitamins, carbohydrates and fats to both mulching animals and gains weight in meat providing animals & birds . Our product aims to get animals to milk to their genetic potential and to gain weight to its right potential for meat providing animals/birds to maximize profit for their owners. At the same time our product optimizes the health and future reproductive performance of animals.</p>
          </div>
          <h4 className='tab-head'>What are the advantages and its health benefit?</h4>
          </div>
         <div className='Ad-wrapper'>
           <div className='Ad-float'>
           <h5 style={{color:'#307230'}}>Health benefits for mulching animals</h5>
           <ul>
             <li>Hypogalactia</li>
             <li>Irregular Lactation</li>
             <li>Failure of Lactation</li>
             <li>Low yield of milk production in healthy animals</li>
           </ul>
           </div>
           <div className='Ad-float'>
           <h5 style={{color:'#307230'}}>Health benefits for livestock </h5>
           <ul>
             <li>Hypogalactia</li>
             <li>Irregular Lactation</li>
             <li>Failure of Lactation</li>
             <li>Low yield of milk production in healthy animals</li>
           </ul>
           </div>

           <div className='Ad-float'>
           <h5 style={{color:'#307230'}}>Dosage and administration </h5>
           <ul>
             
             <li>10 ML per 100 kg mixed with the feed daily
give orally twice daily for 15 days.
</li>
            <li> Maintain the doses in to attain the desired results</li>
             
           </ul>
           </div>
         </div>
         <div className='Ad-btn'>
        <button><Link to='/AnimalDetails' className='btn-content'>
             Know more... 
          </Link></button>
          </div>
        </div>

        <div
          className={toggleState === 3 ? "content  active-content" : "content"}
        >
          <h4>Nano disinfekt</h4>
          <hr/>

          <ul className='Nd-content'>
            <li>Hand cop</li>
            <li>Fogging spray</li>
            <li>Fruggie clean</li>
            <li>Home clean</li>
            <li>Laundry clean</li>
            <li>Travel cop</li>
          </ul>
         <div className='Nd-wrapper'>
          <div className='Nd-float'> 
            <div className='pr-nano'>
            <img className='pr-riddhi'
                src={img4}
                alt='Nano' />
                <span className='pr-content'><span className='pr-color5'>Hand cop</span></span>
            </div>

            <div className='pr-nano'>
            <img className='pr-riddhi'
                src={img5}
                alt='Nano' />
                <span className='pr-content'><span className='pr-color6'>Fogging spray</span></span>
            </div>

            <div className='pr-nano'>
            <img className='pr-riddhi'
                src={img6}
                alt='Nano' />
                <span className='pr-content'><span className='pr-color7'>Fruggie clean</span></span>
            </div>

            <div className='pr-nano'>
            <img className='pr-riddhi'
                src={img7}
                alt='Nano' />
                <span className='pr-content'><span className='pr-color8'>Home clean</span></span>
            </div>

            <div className='pr-nano'>
            <img className='pr-riddhi'
                src={img8}
                alt='Nano' />
                <span className='pr-content'><span className='pr-color9'>Travel cop</span></span>
            </div>
            <div className='pr-nano'>
            <img className='pr-riddhi'
                src={img9}
                alt='Nano' />
                <span className='pr-content'><span className='pr-color10'>Laundry clean</span></span>
            </div>
            </div>
            </div>
        </div>
      </div>
    </div> 
    <div>
           <h1 className='pr-head-1'>Why & how we will enhance your bussines see below video </h1>
           </div>
        <div className='pr-center'>
        <video className='pr-video'
           src={v5}
           autoPlay
           muted
           loop
           controls />
        </div>
        <div className='pr--content'>
          <h1 className='pr-head'>Why are we different from other products?</h1>
          <div>
          <p>
          Designed for high trace mineral bioavailability it is an elite, cutting-edge Nano -micro mineral and vitamins with proven consistent consumption designed to get animals well grown without any health hazards and deliver maximum respective produce. These minerals and vitamins offer ultimate trace mineral absorption through a combination of organic and inorganic sources, to enhance better reproductive expectations. These minerals contain the proper balance and ratios of all 14 essential minerals required by animal & birds which they need for health, growth and reproduction.
          <br></br>
          <br></br>
          The mineral’s proprietary blend of nutritional additives promote digestion, gut health and energy utilization to combat environmental stress in the summer so cows can maintain consumption and productivity. 
          </p>
        </div>
        </div>
        <h1 className='pr-portfolio'>Portfolio film</h1>
        <div>
        <div className='pr-center'>
        <video className='pr-video'
           src={v6}
           autoPlay
           muted
           loop
           controls />
        </div>
        </div>
        <div className='pr--content'>
          <h1 className='pr-head'>What are the features and benefits?</h1>
          <div>
          <p>
          Balanced mineral nutrition of nano nutrient feed supplement contains the proper balance and ratios of all 14 essential minerals and vitamins, that includes the right balance of trace minerals and vitamins for fortification during times of heat stress when immune function can be impaired.
          <br></br>
          <br></br>
          Support gut health and metabolism:  It is formulated to support the physiological changes in the animals that can occur due to heat stress with a combination of chromium for optimal energy utilization, as well as organic zinc and essential oils to support digestive performance and immune response.
          <br></br>
          <br></br>
          <li>Premium vitamin package</li>
          <li>Consistent intake and less waste</li>
          <li>Improve palatability</li>
          <li>Immunity enhancement</li>
          <li>Natural health support</li>
          </p>
        </div>
        </div>
        </div>
        
   <ReactWhatsapp
        number='+91 8368844238'
        className="whatsapp_float"
        message='Hello! '>
        <i className="fa fa-whatsapp whatsapp-icon"></i>
    </ReactWhatsapp>
    </section>
         </>
    )
}

export default ProductBrand;


